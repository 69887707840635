.family-dinner-section {
    background-color: #d5e5d5; /* Light greenish background */
    padding: 50px 0;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #e5a836; /* Orange-ish text */
  }
  
  .title-underline {
    width: 50%;
    margin: 0 auto;
    border: 5px solid #e5a836; /* Same color as title for horizontal line */
  }
  
  .price {
    font-size: 24px;
    margin-bottom: 20px;
    color: #683226; /* Dark red text */
  }
  
/* Menu card with organic uneven shape */
.menu-card {
    background-color: #a83232; /* Deep red background */
    color: #fff; /* White text */
    margin-bottom: 20px;
    height: 410px; /* Set equal height for all cards */
    text-align: center;
    border-radius: 30px;
  }
  

  
  .menu-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  

  
  .see-menu-btn {
    background-color: #a83232;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 10px;
    border: none;
  }
  
  .see-menu-btn:hover {
    background-color: #902929;
  }
  
